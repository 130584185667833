// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

// s4n
import GeneralUtils from './../utils/common'
import { useIntl } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import Menu from "./s4n/Menu/menu"
import MenuUtils from "./../components/s4n/Menu/utils"
import LanguageSwitcher from './../components/s4n/LanguageSwitcher'
import Search from './../components/forms/Search';
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"

import Logo from './../images/custom/logo.png'
// import LogoWhite from './../images/custom/logo-white-48.png'

// import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import withDrupalOauthConsumer from '../components/drupal-oauth/withDrupalOauthConsumer';
import Login from './s4n/AccountManagement/Login';
import LogoutLink from './s4n/AccountManagement/LogoutLink';

// import Accessibility from './s4n/Accessibility'
import PromotedFrontPage from './s4n/Promoted/FrontPage'
import Accessibility from './s4n/Accessibility'

import IconFacebook from './../images/custom/icons/facebook.svg'
import IconInstagram from './../images/custom/icons/instagram.svg'
import IconPinterest from './../images/custom/icons/pinterest.svg'
// import IconSearch from './../images/custom/icons/search.svg'
import IconUserAccount from './../images/custom/icons/user-account.svg'
import IconUserWishlist from './../images/custom/icons/user-wishlist.svg'
// import IconCart from './../images/custom/icons/cart.svg'



// Shop START
import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// Shop STOP






import classNames from 'classnames';
// import { connect } from 'react-redux'
// import { bindOnClickOnKeyDown } from './s4n/Accessibility'
import $ from "jquery"



import useScrollHandler from './../hooks/LayoutUtilities/useScrollHandler'

import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
import { globalHistory } from "@reach/router"
import { connect } from 'react-redux'

import moment from 'moment'
import EventEntity from './s4n/AccountManagement/Events/Entity'
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"
import { selectEventTypeByUuid } from './s4n/Shop/ext/redux/selectors/event'
import { kebabCase as _kebabCase } from "lodash"


// ES6
//import Webform from 'hn-react-webform';

const Header = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { nodeId, siteTitle, userAuthenticated: isUserAuthenticated } = props;

// console.log(`notification.data: `, props.notification.data)
    const mappedIncludes = getMappedIncludes(props.notification.included);


    // $(".nav-control").on('click', () => {
    //   $('#main-wrapper').toggleClass("menu-toggle");
    //   $(".hamburger").toggleClass("is-active");
    // });
    const hamburger = useRef(null);
    const navControlClickHandler = (e) => {
        document.getElementById('main-wrapper').classList.toggle('menu-toggle');
        hamburger.current.classList.toggle('is-active');
    }

    return (
      <React.Fragment>

        <div className="nav-header">
            <a href="/" className="brand-logo">
                <img className="logo-abbr" src={Logo} alt={siteTitle} />
            </a>

            <div className="nav-control" onClick={navControlClickHandler}>
                <div className="hamburger" ref={hamburger}>
                    <span className="line"></span><span className="line"></span><span className="line"></span>
                </div>
            </div>
        </div>

        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            {/* <div className="search_bar dropdown">
                                <span className="search_icon p-3 c-pointer" data-toggle="dropdown">
                                    <i className="mdi mdi-magnify"></i>
                                </span>
                                <div className="dropdown-menu p-0 m-0">
                                    <form>
                                        <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                                    </form>
                                </div>
                            </div> */}
                        </div>

                        {isUserAuthenticated &&
                          <ul className="navbar-nav header-right">
                              {!!props?.notification?.data?.length &&
                                <li className="nav-item dropdown notification_dropdown      ___show">
                                    <a className="nav-link" href="#" role="button" data-toggle="dropdown">
                                        <i className="mdi mdi-bell"></i>
                                        <div className="pulse-css"></div>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right     ___show">
                                        <ul className="list-unstyled">
                                            {props.notification.data.map(item => { // "node--event"

                                              const field_booking_info = EventEntity.getFieldBookingInfo(item, { intl });
                                              const purchased_entity = EventEntity.getOrderItemsPurchasedEntity(item, { mappedIncludes });
                                              const relationshipFieldEventType = props.selectEventTypeByUuid(item.relationships.field_event_type.data.id);
// console.log(`purchased_entity: `, purchased_entity)
                                              // let duration = ``;
                                              // if (item.attributes?.field_booking_info) {
                                              //     const now = moment(item.attributes.field_booking_info.end_value);
                                              //     const then = moment(item.attributes.field_booking_info.value);
                                              //     duration = item.attributes.field_booking_info.duration || moment.duration(now.diff(then));
                                              // }
                                              // const field_booking_info = item.attributes?.field_booking_info ? `
                                              //     ${GeneralUtils.dateFormat(item.attributes.field_booking_info.value, `LLL`, intl.locale)} - ${GeneralUtils.dateFormat(item.attributes.field_booking_info.end_value, `LLL`, intl.locale)} / ${GeneralUtils.duration(duration, null, intl.locale)} h
                                              // ` : `-`;                                            

                                              return (
                                                <li className="media dropdown-item">
                                                    <span className="danger"><i className="ti-user"></i></span>
                                                    <div className="media-body">
                                                        <a href="#">
                                                            <strong>
                                                              {/* Zdarzenie{` `} */}
                                                              <span 
                                                                  style={{ backgroundColor: relationshipFieldEventType?.attributes.field_color }}
                                                                  className={`badge event-type mr-1 ${_kebabCase(relationshipFieldEventType?.attributes.name)}`}
                                                              >
                                                                  {/* {t({ id: `soft4net_shop_commerce_account_management_commerce_orders_status_${relationshipFieldEventType.attributes.name}` })} */}
                                                                  {relationshipFieldEventType?.attributes.name}
                                                              </span>
                                                            </strong>
                                                            {purchased_entity.length &&
                                                              <ul>
                                                                  {purchased_entity.map((commerceProductVariationDefault, index) => { // "commerce_product_variation--default"
                                                                    return (
                                                                      <li key={`notification-order-item-${index}`}>
                                                                        {commerceProductVariationDefault.attributes.title} ({commerceProductVariationDefault.attributes.sku})
                                                                      </li>
                                                                    )
                                                                  })} 
                                                              </ul>
                                                            }
                                                        </a>
                                                    </div>
                                                    <span className="notify-time">{field_booking_info}</span>
                                                </li>
                                              )
                                            })}
                                            {/* <li className="media dropdown-item">
                                                <span className="primary"><i className="ti-shopping-cart"></i></span>
                                                <div className="media-body">
                                                    <a href="#">
                                                        <p><strong>Jennifer</strong> purchased Light Dashboard 2.0.</p>
                                                    </a>
                                                </div>
                                                <span className="notify-time">3:20 am</span>
                                            </li>
                                            <li className="media dropdown-item">
                                                <span className="danger"><i className="ti-bookmark"></i></span>
                                                <div className="media-body">
                                                    <a href="#">
                                                        <p><strong>Robin</strong> marked a <strong>ticket</strong> as unsolved.
                                                        </p>
                                                    </a>
                                                </div>
                                                <span className="notify-time">3:20 am</span>
                                            </li>
                                            <li className="media dropdown-item">
                                                <span className="success"><i className="ti-image"></i></span>
                                                <div className="media-body">
                                                    <a href="#">
                                                        <p><strong> James.</strong> has added a<strong>customer</strong> Successfully
                                                        </p>
                                                    </a>
                                                </div>
                                                <span className="notify-time">3:20 am</span>
                                            </li> */}
                                        </ul>
                                        {/* <a className="all-notification" href="#">See all notifications <i className="ti-arrow-right"></i></a> */}
                                    </div>
                                </li>
                              }

                              <li className="nav-item dropdown header-profile">
                                  <a className="nav-link" href="#" role="button" data-toggle="dropdown">
                                      <i className="mdi mdi-account"></i> {props?.user?.user_profile?.attributes?.display_name}
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                      {/* <a href="./app-profile.html" className="dropdown-item">
                                          <i className="icon-user"></i>
                                          <span className="ml-2">Profile </span>
                                      </a> */}
                                      <Link to={`/user/profile`} className="dropdown-item" href="#">
                                          <i className="icon-user"></i>
                                          <span className="ml-2">
                                            {/* Profile */}
                                            { t({ id: `soft4net_menu_account_management_user_profile` }) }
                                          </span>
                                      </Link>

                                      {/* <Link to={`/events`} className="dropdown-item" href="#">
                                          <i className="icon-user"></i>
                                          <span className="ml-2">
                                            { t({ id: `soft4net_menu_account_management_events` }) }
                                          </span>
                                      </Link> */}

                                      {/* <a href="./email-inbox.html" className="dropdown-item">
                                          <i className="icon-envelope-open"></i>
                                          <span className="ml-2">Inbox </span>
                                      </a> */}

                                      {/* <a href="./page-login.html" className="dropdown-item">
                                          <i className="icon-key"></i>
                                          <span className="ml-2">Logout </span>
                                      </a> */}
                                      <LogoutLink component={Link} className="dropdown-item mt-2">
                                          <i className="icon-key"></i>
                                          <span className="ml-2">Logout</span>
                                      </LogoutLink>

                                  </div>
                              </li>
                          </ul>
                        }

                    </div>
                </nav>
            </div>
        </div>






        {/* 
        <Menu type="main" injectContentToMenuId={injectContentToMenuId} />
        
        <Search />
        
        <Link 
          {...linkLogoProps}
          title={siteTitle} 
          className="navbar-brand"
        >
          <img src={Logo} alt={siteTitle} className="img-fluid logo" />
        </Link>
        
        <Login />

        <div id="menu-account-management" className="account-item dropdown show">
          
          {isUserAuthenticated ?
            <React.Fragment>
              <a className="_btn _btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLinkAccountManagement" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src={IconUserAccount} alt={ t({ id: `soft4net_user_account` }) } />
                <span className={`d-none d-xl-inline-block`}>{props?.user?.user_profile?.attributes?.display_name}</span>
              </a>
            </React.Fragment>
            :
            <React.Fragment>
              <a className="_btn _btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLinkAccountManagement" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src={IconUserAccount} alt={ t({ id: `soft4net_user_account` }) } />
                <span className={`d-none d-xl-inline-block`}>{ t({ id: `soft4net_user_account` }) }</span>
              </a>
            </React.Fragment>
          }

          <div className="dropdown-menu" aria-labelledby="dropdownMenuLinkAccountManagement">
            {!isUserAuthenticated && 
              <React.Fragment>
                <p className="px-4 mb-0 text-center">{ t({ id: `soft4net_menu_account_management_encouragement_create_account` }) }</p>
                <Link to={`/user/login-register`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_login` }) }</Link>
                <p className="px-4 mb-0 text-center">{ t({ id: `soft4net_menu_account_management_encouragement_create_account` }) }</p>
                <Link to={`/user/login-register`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_register` }) }</Link>
              </React.Fragment>
            }
            <Link to={`/user/profile`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_user_profile` }) }</Link>
          </div>
        </div>

        <CartFlyout />

        <div className={`d-none`}>
          <LanguageSwitcher currentMenuItemTranslations={MenuUtils.getCurrentMenuItemTranslations()} />
        </div> 
        */}



      </React.Fragment>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => ({
  user: state.shopReducers.user,
  notification: state.shopReducers.event.notification,
  selectEventTypeByUuid: (uuid) => selectEventTypeByUuid(state, uuid),
})

export default connect(mapStateToProps)(withDrupalOauthConsumer(Header));
