import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

export const selectUserState = (state) => state.shopReducers?.user || null;
export const selectUserProfileEntity = (state) => selectUserState(state)?.user_profile;
export const selectUserRoleEntities = (state) => {
    const user = selectUserState(state);
    const mappedIncludes = getMappedIncludes(user.included);
    const roles = getRelationshipFromMappedIncludes(selectUserProfileEntity(state), `roles`, mappedIncludes);

    return roles;
};
export const selectUserIsAdmin = (state) => {
    return selectUserRoleEntities(state)?.find(role => role.attributes.is_admin) || false;
};
export const selectUserAuthenticationDetails = (state) => {
    return selectUserState(state)?.auth || null;
};
export const selectUserLoginIsAllowed = (state) => {
    return selectUserState(state)?.login_is_allowed || false;
};
export const selectUserMonthWorkedHours = (state) => {
    return selectUserState(state)?.month_worked_hours || 0;
}