import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { cartRemove, cartUpdateItem } from '../redux/actions/cart'
import { cartFlyoutOpen, cartFlyoutClose } from '../redux/actions/cartFlyout'
// import { Link } from 'react-router-dom'
import { MdClose } from 'react-icons/md'
import { getMappedIncludes, getRelationshipFromMappedIncludes } from '@centarro/js-sdk'

// s4n
import { useIntl, Link, navigate } from "gatsby-plugin-intl"
import Preloader from './../../../../s4n/Preloader'
import Product from './../../../../../model/commerce/product'
import ProductImage from './../../Product/image'
import CommerceHelper from './../../s4n/utils/commerce-helper'
import ProductQuantityAddToCart from './../../../Shop/Product/Quantity'
import { globalHistory } from "@reach/router"


import IconCart from './../../../../../images/custom/icons/cart.svg'
import { formatCurrency } from './../../ext_old/utils/currency'


const CartFlyout_NEW = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const {
    dispatch,
    cart: {
      carts,
      included,
      itemCount
    },
    cartFlyout: { open }
  } = props;
  const mappedIncludes = getMappedIncludes(included);

  return (
    <Fragment>

      <button id="cartOpen" className="cart-btn" onClick={() => props.openFlyout()} title={ t({ id: `soft4net_shop_cart_flyout_header` }) }>
        <i className="ti-bag"></i>
        <span className="d-xs-none">{ t({ id: "soft4net_shop_cart_flyout_header" }).toUpperCase() }</span> 
        {/* {getCartItemsCount()} */}
      </button>

      <aside id="cart-offcanvas" className={`cart-offcanvas is-${open ? 'open' : 'closed'} cart-offcanvas--right`}>
        <div className="cart--cart-offcanvas well well-lg well-primary text-light">
          <div className="cart--cart-offcanvas__close">
            <button type="button" className="button btn close-btn"><span className="visually-hidden"> Close cart</span></button>
          </div>
          {CommerceHelper.isCartEmpty(props) ? <div key={`empty`}>Your cart is empty</div> : [
            <div className={`cart-block--offcanvas-contents`} key={`contents`}>
              <div className={`cart-block--offcanvas-contents__inner`}>
                <h2>Shopping bag</h2>
                <div className={`cart-block--offcanvas-contents__items`}>
                  <table className={`cart-block--offcanvas-cart-table table`}>
                    <tbody>
                    {carts[0].relationships.order_items.data && carts[0].relationships.order_items.data.map(rel => mappedIncludes[rel.type][rel.id]).map(orderItem => {
                      const purchaseEntity = getRelationshipFromMappedIncludes(orderItem, 'purchased_entity', mappedIncludes);
                      const product = getRelationshipFromMappedIncludes(purchaseEntity, 'product_id', mappedIncludes);

// console.log(product)

                      return (
                        <tr key={orderItem.id}>
                          <td className="cart-block--offcanvas-cart-table__title align-middle w-50">
                            <Link className={`text-light`} to={product.attributes.path.alias}>{orderItem.attributes.title}</Link>
                          </td>
                          <td className="cart-block--offcanvas-cart-table__quantity align-middle w-25">
                            <input className="form-control invert" type={`number`} min={0} value={parseInt(orderItem.attributes.quantity)} onChange={e => props.cartUpdateItem(orderItem, {quantity: e.target.value})}/>
                          </td>
                          <td className="cart-block--offcanvas-cart-table__price align-middle text-light">
                            {orderItem.attributes.total_price.formatted}
                          </td>
                          <td className="cart-block--offcanvas-cart-table__remove align-middle">
                            <button className="btn btn-link text-light" onClick={() => props.cartRemove(orderItem)}><MdClose/></button>
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                    <tfoot>
                    {/* <tr>
                      <td className={`text-right`} colSpan={4}>
                        <button type="submit" className="cart-block--offcanvas-contents__update btn btn-link text-light">Update quantities</button>
                      </td>
                    </tr> */}
                    </tfoot>
                  </table>
                </div>
                <div className="cart-block--offcanvas-contents__links text-center">
                  
                  <Link to="/cart" className={`btn w-100 btn-outline-light`}>View cart</Link>
                  {/* {CommerceHelper.getCheckoutLink(props)} */}

                </div>
                <div className="cart--cart-offcanvas__close d-md-none text-center">
                  <button type="button" onClick={() => props.closeFlyout()} className="btn text-light btn-link">Continue shopping</button>
                </div>
              </div>
            </div>
          ]}
        </div>
      </aside>
      <div id="cart-offcanvas-bg" className={`cart-offcanvas-bg is-${open ? 'open' : 'closed'}`} onClick={() => props.closeFlyout()} />
    </Fragment>
  )
}








const CartFlyout = (props) => {
	const intl = useIntl();
	const t = intl.formatMessage;

/*
	const {
		cart: { cartToken, carts, itemCount },
		cartFlyout: { open },
  } = props;
*/
  const {
    dispatch,
    cart: {
      cartToken,
      carts,
      itemCount,
      included,
    },
    cartFlyout: { open }
  } = props;
  const mappedIncludes = getMappedIncludes(included);

	// Returns default cart, Drupal Commerce allow to have multiple carts
	const getCart = () => {
		const { cart: { carts } } = props;
    const defaultCart = carts[0];
    
    if (!CommerceHelper.isCartEmpty(props)) {
      return defaultCart;  
    }

    return defaultCart;
// console.log(defaultCart)
    


		if (typeof defaultCart === `undefined`) {
			const cart = {
				order_items: [],
			}
			return cart;
		}

		return defaultCart;
	}
  const cart = getCart();
  
  // console.log(carts)
  // console.log(cart)

	const isBrowser = typeof window !== 'undefined';

  useEffect(() => {
      componentDidMount();
  }, []);

  const componentDidMount = () => {

    // @see: https://github.com/reach/router/issues/262
    // Whenever you switch routes, the globalHistory.listen will fire
    // We sue this because reducer actio type of '@@router/LOCATION_CHANGE' in src/components/s4n/Shop/ext/redux/reducers/cartFlyout.js is not working!!!
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        props.closeFlyout();
      }
    })

/*
    const defaultCart = getCart();

    if (typeof defaultCart === `undefined`) {
      // resetCartToken();
    }
*/
  }


	/**
	 * @see: https://stackoverflow.com/questions/5732043/javascript-reduce-on-array-of-objects
	 * Accumulator in each iteration becomes what was returned from previous iteration!!!
	 */
	const getCartItemsCount = () => {
		const defaultCart = getCart();
    let countedCartItems = 0;

    if (!CommerceHelper.isCartEmpty(props)) {
      countedCartItems = defaultCart.relationships.order_items.data.length;
    }

    return countedCartItems;



// console.log(defaultCart)

		if (!defaultCart.order_items.length) {
			return countedCartItems;
		}

		// Here accumulator becomes Object
		const oCountCartItems = defaultCart.order_items.reduce((accumulator, orderItem) => {
			// quantity is a String it has to be an Integer!
			// returns object with property quantity
			return {
				quantity: parseInt(accumulator.quantity) + parseInt(orderItem.quantity)
			};
		});
		countedCartItems = parseInt(oCountCartItems.quantity);

		/**
		 * We provide an initialValue as the second argument to reduce(), so accumulator becomes integer, 
		 * not undefined, this is because we extract object and accumulator is not aware of this
		 */
		// Here accumulator becomes Integer
        // const iCountCartItems = defaultCart.order_items.reduce((accumulator, orderItem) => {
        //   	return accumulator + parseInt(orderItem.quantity);
		// }, countedCartItems);
		// countedCartItems = parseInt(iCountCartItems);

		return countedCartItems;
	}

	let cartOrderItemsTotalPriceNumberTotalWithoutDiscounts = 0.0;

	return (
		<React.Fragment>
			<div className={`cart ${open ? `open` : `closed`}`}>
        {
        // typeof cart === `undefined` ? 
        typeof cart === 'POPRAWIC ZEBY SIE PRELOADER POKAZYWAL PODCAS POBIERANIA KOSZYKA :)' ? 
				[
					<Preloader size="1rem" paddingY="0" />
				] : 
				<React.Fragment>
					<button id="cartOpen" className="cart-btn" onClick={() => props.openFlyout()} title={ t({ id: `soft4net_shop_cart_flyout_header` }) }>
						<img src={IconCart} alt={ t({ id: "soft4net_shop_cart_flyout_header" }) } />
						{/* <span className="d-xs-none">{ t({ id: "soft4net_shop_cart_flyout_header" }) }</span>{` `}({getCartItemsCount()}) */}
            <span className={`d-none d-xl-inline-block`}>
              { t({ id: "soft4net_shop_cart_flyout_header" }) } ({getCartItemsCount()})
            </span>
            <span className={`counter mobile`}>
              {getCartItemsCount()}
            </span>

					</button>
					<div className={`cart-wrapper ${open ? `open` : `closed`}`}>
						<i id="cartClose" className={`ti-close cart-close`} onClick={() => props.closeFlyout()} title={ t({ id: "soft4net_shop_cart_flyout_close_cart" }) }></i>
						<h4 className="mb-4">{ t({ id: "soft4net_shop_cart_flyout_header" }) }</h4>
	
						{CommerceHelper.isCartEmpty(props) ? [
							<div key={`empty`} className="cart-empty mb-3">{ t({ id: "soft4net_shop_cart_flyout_empty_cart" }) }</div>
						] : [
							<ul className="pl-0 mb-3">
								{/* Cart display items START */}
								{carts[0].relationships.order_items.data && carts[0].relationships.order_items.data.map(rel => mappedIncludes[rel.type][rel.id]).map(orderItem => {
                  const purchaseEntity = getRelationshipFromMappedIncludes(orderItem, 'purchased_entity', mappedIncludes);
                  const product = getRelationshipFromMappedIncludes(purchaseEntity, 'product_id', mappedIncludes);
                  const variation = getRelationshipFromMappedIncludes(product, 'variations', mappedIncludes);



                  const drupal_internal__product_id = product.attributes.drupal_internal__product_id;
                  const commerceProduct = Product.getItemByProductId(drupal_internal__product_id, intl.locale);
                  // console.log(commerceProduct)
                  const commerceProductPurchasedVariartion = CommerceHelper.getProductPurchasedVariartion(commerceProduct, purchaseEntity);


// console.log(orderItem.attributes.total_price) // currency_code | formatted | number
// console.log(orderItem.attributes.unit_price) // currency_code | formatted | number


// console.log(orderItem)
// console.log(commerceProductPurchasedVariartion)
// console.log(commerceProduct)
// console.log(orderItem.total_price.number) // if 1200 x 2, so it shows 2400 here !!!
// cartOrderItemsTotalPriceNumberTotalWithoutDiscounts += Number(orderItem.total_price.number);



// Replace title for package product START
                  const aProductsPackage = process.env.SOFT4NET_PRODUCTS_PACKAGE.split(',').map(v => Number(v)); // [601, 602]
                  const isProductPackage = aProductsPackage.includes(drupal_internal__product_id);
                  const orderItemTitle = isProductPackage ? orderItem.attributes.title.split(` - `)[0] : orderItem.attributes.title;
// Replace title for package product STOP



									return (
										<li key={orderItem.id} className="d-flex flex-nowrap border-bottom row">
											{/* <img src="/elite-shop/images/cart/product-1.jpg" alt="product-img" /> */}
                      <div className={`_col`} style={{ width: `4rem` }}>
                        <Link to={product.attributes.path.alias} className={`___text-light`}>
                          <ProductImage defaultVariation={commerceProductPurchasedVariartion} />
                        </Link>
                      </div>
											<div className="col">
												<h6 className={`mb-0`}>
													<Link to={product.attributes.path.alias} className={`___text-light`}>
                            {orderItemTitle}
													</Link>
												</h6>
											</div>
                      <div className={`col-auto`}>
                          {/* <input style={{width: `3rem`}} _className="form-control invert" type={`number`} min={0} value={parseInt(orderItem.attributes.quantity)} onChange={e => props.cartUpdateItem(orderItem, {quantity: e.target.value})} /> */}
                          <div className={`d-inline-flex`}>
                            <ProductQuantityAddToCart displayAsSmall={true} quantity={parseInt(orderItem.attributes.quantity)} onQuantityModify={quantity => props.cartUpdateItem(orderItem, {quantity: quantity})} />
                          </div>
                          {/* <span>{parseInt(orderItem.attributes.quantity)}</span>  */}
                      </div>
                      <div className={`col-auto px-0`}>X</div>
                      <div className={`col-auto`}>
                        <span>
                          {/* {formatCurrency(orderItem.total_price.currency_code, orderItem.total_price.number)} */}
                          {orderItem.attributes.total_price.formatted}
                        </span>
                      </div>
                      {/* <div className={`col-auto px-0`}> */}
                        <i onClick={() => props.cartRemove(orderItem)} className="ti-close">
                          {/* <MdClose/> */}
                        </i>
                      {/* </div> */}
										</li>
									)
								})}
	
                {/* Discounts */}
                {cart.attributes.order_total.adjustments.map(adjustment => {
                  return (
                    <li key={adjustment.type} className={`_d-flex py-2 adjustment-${adjustment.type}`}>
                      <div className="_mt-1">
                        <span>{adjustment.label}</span>
                        <span className="float-right">
                          {adjustment.amount.formatted}
                        </span>
                      </div>
                    </li>
                  )
                })}

                {/* Totals */}
                <li key={`cart.total_price.total`} className="_d-flex py-2 border-top">
                  <div className="_mt-1">
                    <span>{ t({ id: `Total` }) }</span>
                    <span className="float-right">
                      {cart.attributes.total_price.formatted}
                    </span>
                  </div>
                </li>
	
								{/* <button type="submit" className="cart-block--offcanvas-contents__update btn btn-link ___text-light">
									{ t({ id: "soft4net_shop_cart_flyout_update_quantities" }) }
								</button> */}
	
							</ul>
	
						]}
						<div className="row text-center">
							{/* <Link to="/cart" className={`btn btn-dark btn-mobile rounded-0`}>View cart</Link> */}
							{/* <a href="shipping.html" className="">check out</a> */}
							{/* {getCheckoutLink()} */}

              <div className={`col-6`}>
                <a 
                  onClick={(event) => {
                    event.preventDefault();
                    props.closeFlyout();
                    navigate(`/wianki`);
                  }} 
                  className={`btn w-100 _btn-outline-light`}
                >{ t({ id: "soft4net_shop_cart_flyout_action_continue_shopping" }) } </a>

                

              </div>
              <div className={`col-6`}>
                <Link to="/cart" className={`btn w-100 _btn-outline-light`}>{ t({ id: "soft4net_shop_cart_flyout_go_to_cart" }) }</Link>
              </div>
						</div>
					</div>
					<div id="cart-bg" className={`cart-bg`} onClick={() => props.closeFlyout()}></div>
				</React.Fragment>
				}
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
    return {
      cart:       state.shopReducers.cart,
      cartFlyout: state.shopReducers.cartFlyout,
    }
};
  
const mapDispatchToProps = dispatch => ({
    openFlyout: () => dispatch(cartFlyoutOpen()),
    closeFlyout: () => dispatch(cartFlyoutClose()),
    cartUpdateItem: (orderItem, attributes) => dispatch(cartUpdateItem(orderItem, attributes)),
    cartRemove: (orderItem) => dispatch(cartRemove(orderItem)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartFlyout);