import React from "react"
import AccessibilityHighContrast from './HighContrast'
import AccessibilityFontSize from './FontSize'

// @see: https://stackoverflow.com/questions/42998927/accessibility-react-ensure-click-events-have-key-events
export const bindOnClickOnKeyDown = (handlerFn) => {
    return {
        // role: `button`,
        onClick: handlerFn,
        onKeyDown: event => {
            // console.log(event.key)
            // insert your preferred method for detecting the keypress
            if (event.keyCode === 13) { // Enter
            // if(event.key === 'Enter') { // Enter
                handlerFn(event);
            }
        }
    }
}

export default (props) => {
    // const wrapper = props.wrapper || false;

    if (`true` !== process.env.SOFT4NET_ACCESSIBILITY) {
        return null;
    }

    return (
        <React.Fragment>
            <AccessibilityHighContrast />
            <AccessibilityFontSize />
        </React.Fragment>
    )
}