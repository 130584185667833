/**
 * Menu component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
// import PropTypes from "prop-types"

// s4n
import { useIntl } from "gatsby-plugin-intl"
import MenuLevelGenerator from './menu-level-generator'
// import LanguageSwitcher from './../LanguageSwitcher'
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
import MenuUtils from "./utils"
// import classNames from 'classnames';

// @see: https://www.drupal.org/project/jsonapi_extras/issues/2942851



//const Menu = ({ children }) => (
const Menu = (props) => {
    const intl = useIntl();
    // const t = intl.formatMessage;

    // const homeMenuItem = {
    //     node: {
    //         changed: "2019-04-27T22:41:56+00:00",
    //         children: [],
    //         id: MenuUtils.getUniqueId(),
    //         drupal_id: MenuUtils.getUniqueId(),
    //         enabled: true,
    //         expanded: false,
    //         fields: {
    //             parent_menu_id: null,
    //         },
    //         langcode: intl.langcode,
    //         link: {
    //             url: "/",
    //             title: null
    //         },
    //         menu_name: "main",
    //         title: t({ id: "Home" }),
    //         weight: -9999,
    //     }
    // }

    // filter menu for current locale
    let translatedMenu = MenuUtils.getAllItemsTranslated(intl.locale);
    // console.log(translatedMenu)
    
    const flatMenu = [
        // homeMenuItem,

        // This page won't be visible if there's no route created by Gatsby or file placed in ./src/pages! START
        // harmonogramMenuItem,
        // blogMenuItem,
        // This page won't be visible if there's no route created by Gatsby or file placed in ./src/pages! STOP

        // ...data.allMenuLinkContentMenuLinkContent.edges
        ...translatedMenu
    ];
    // flatMenu.unshift(homeItem);
    // console.log([...translatedMenu])
    // return null;

    const parent = null;
    const menuNestedTree = MenuUtils.getNestedChildren(flatMenu, parent);
    
    // console.log(menuNestedTree);

    let menuNestedTreeFiltered = menuNestedTree;

    // filter root menu to start menu at!!!
    if (props.nodeLinkUriRoot) {
        menuNestedTreeFiltered = menuNestedTree.filter(({ node }) => {
            return props.nodeLinkUriRoot === node.link.uri;
        })
    }

    return <MenuLevelGenerator {...props} key={`key-nav-menu-type-${props.type}-level-0`} data={menuNestedTreeFiltered} level={0} />
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// Menu.propTypes = {
//     scrolled: PropTypes.bool,
//     showSidebar: PropTypes.func,
//     darkMenu: PropTypes.bool,
//     isMobile: PropTypes.bool
// };
  
// Menu.defaultProps = {
//     scrolled: false,
//     showSidebar: null,
//     darkMenu: false,
//     isMobile: false
// };

export default Menu