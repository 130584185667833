import React from 'react';
import { connect } from 'react-redux'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
// import LogoutLink from './../LogoutLink';
import axios from 'axios';

import Logo from './../../../../images/custom/logo.png'
import { 
    userLogin, 
    // userRegister, 
    // userPasswordReset
} from './../../../s4n/Shop/ext/redux/actions/user'

import GeneralUtils from './../../../../utils/common'


/*
interface IOAuthAuthorizationResponse {
    access_token: string,
    date: number,
    expirationDate: number,
    expires_in: number,
    refresh_token: string,
    token_type: string,
}

interface IDrupalOauth {
    drupalOauthClient: any, 
    userAuthenticated: any, 
    updateAuthenticatedUserState: any,
}

interface IUserAuth {
    authorization: IOAuthAuthorizationResponse,
    oauth: IDrupalOauth,
}
*/


class Login extends React.Component {
    state = {
        open: false,
        processing: false,
        error: null,
        username: ``,
        password: ``,
    };

    constructor(props) {
        super(props);

        this.t = this.props.intl.formatMessage;
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleCancel = () => {
        this.setState({ open: false });
    }

    handleSubmit = async (event) => {
        // event.preventDefault();
        this.setState({ processing: true });
        const { username, password } = this.state;
        const scope = ``;

        try {
            const is_logged_in = await GeneralUtils.login(username, password, scope, this.props);

            // if (!is_logged_in) {
            //     navigate(`/user/access-denied`);
            //     return false;
            // }

            // we were authenticated
            this.setState({ processing: false, open: false });
        } catch (err) {

            // console.log(err)

            this.setState({
                processing: false,
                // error: err.message,
                error: {
                    message: this.t({ id: "soft4net_form_login_form_error" }),
                },
            });
        }
    }



    // @see: https://www.drupal.org/project/simple_oauth/issues/3143106
    loginDrupalUser = async (username, password) => {
        const data = { name: username, pass: password }
        const options = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, withCredentials: true }
        
        try {
            return await axios.post(`${process.env.SOFT4NET_SOURCE_BASE_URL}/user/login?_format=json`, data, options);
        } catch (e) {
            return e;
        }
    }



    render() {
        const isUserAuthenticated = this.props.userAuthenticated;
        // console.log('isUserAuthenticated: ', isUserAuthenticated);
    
        // const _token = this.props.drupalOauthClient.isLoggedIn();
        // console.log('_token: ', _token);

        // Redirect after login right away to profile page
        if (isUserAuthenticated) {
            navigate(`/user/profile`);
        }

        return (
            <React.Fragment>

                <div className="authincation h-100">
                    <div className="container _container-fluid h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <h4 className="text-center mb-4">
                                                    {/* Sign in your account */}
                                                    <a href="/" className="brand-logo">
                                                        <img className="logo-abbr" src={Logo} alt={``} />
                                                    </a>
                                                </h4>
                                                <form action="index.html">
                                                    <div className="form-group">
                                                        {/* <label><strong>{ this.t({ id: "soft4net_form_login_form_username" }) }</strong></label> */}
                                                        <input 
                                                            type="email" 
                                                            className="form-control" 
                                                            // value="hello@example.com" 

                                                            error={this.state.error}
                                                            helperText={this.state.error && this.state.error.message}
                                                            name="username"
                                                            placeholder="Nazwa użytkownika"
                                                            onChange={event => this.setState({ [event.target.name]: event.target.value })}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <label><strong>{ this.t({ id: "soft4net_form_login_form_password" }) }</strong></label> */}
                                                        <input 
                                                            type="password" 
                                                            className="form-control" 
                                                            // value="Password" 

                                                            error={this.state.error}
                                                            helperText={this.state.error && this.state.error.message}
                                                            name="password"
                                                            placeholder="Hasło"
                                                            onChange={event => this.setState({ [event.target.name]: event.target.value })}
                                                        />
                                                    </div>
                                                    {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                        <div className="form-group">
                                                            <div className="form-check ml-2">
                                                                <input className="form-check-input" type="checkbox" id="basic_checkbox_1" />
                                                                <label className="form-check-label" for="basic_checkbox_1">Remember me</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <a href="page-forgot-password.html">Forgot Password?</a>
                                                        </div>
                                                    </div> */}
                                                    <div className="text-center">
                                                        {/* <button type="submit" className="btn btn-primary btn-block">Sign me in</button> */}
                                                        {this.state.processing ? <CircularProgress /> : <button type="submit" className="btn btn-primary btn-block" onClick={(event) => {this.handleSubmit(event)}}>Login</button>}
                                                    </div>
                                                </form>
                                                {/* <div className="new-account mt-3">
                                                    <p>Don't have an account? <a className="text-primary" href="./page-register.html">Sign up</a></p>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {false &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Zaloguj się</h2>
                                <br />
                                <TextField
                                    error={this.state.error}
                                    helperText={this.state.error && this.state.error.message}
                                    
                                    autoFocus
                                    margin="dense"
                                    name="username"
                                    label={ this.t({ id: "soft4net_form_login_form_username" }) }
                                    type="text"
                                    fullWidth
                                    onChange={event => this.setState({ [event.target.name]: event.target.value })}
                                />
                                <TextField
                                    error={this.state.error}
                                    helperText={this.state.error && this.state.error.message}

                                    margin="dense"
                                    name="password"
                                    label={ this.t({ id: "soft4net_form_login_form_password" }) }
                                    type="password"
                                    fullWidth
                                    onChange={event => this.setState({ [event.target.name]: event.target.value })}
                                />

                                <p>
                                    <Link to={`/user/password/reset`} className={``}>{ this.t({ id: "soft4net_form_login_form_password_reset" }) }</Link>
                                </p>

                                {this.state.processing ? <CircularProgress /> : <Button onClick={(event) => {this.handleSubmit(event)}} color="primary" variant="contained">Log in</Button>}
                            </div>                        
                            <div className="col-md-6">
                                <h2>Nie masz konta?</h2>
                                <br />
                                Dlaczego warto mieć konto w decolly.pl<br />
                                zamawiaj szybciej<br />
                                sprawdzaj poprzednie zamówienia<br />
                                korzystaj z rabatów i promocji
                            </div>                        
                        </div>
                    </div>
                } */}

            </React.Fragment>
        );
    }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    onUserLogin: (payload) => dispatch(userLogin(payload)),
    // onUserRegister: (formData) => dispatch(userRegister(formData)),
    // onUserPasswordReset: (formData) => dispatch(userPasswordReset(formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withDrupalOauthConsumer(Login)));