/*
@see: https://stackoverflow.com/questions/34388614/how-to-force-update-single-page-application-spa-pages
    You can have a React component make an ajax request to your server, when the application loads, to fetch "interface version". 
    In the server API, you can maintain an incremental value for the client version. The React component can store this value on the client (cookie/local storage/etc). 
    When it detects a change, it can invoke window.location.reload(true); which should force the browser to discard client cache and reload the SPA. 
    Or better still, inform the end-user that a new version will be loaded and ask them if they wish to save the work and then reload etc. Depends on what you wanna do.
*/
// @see: https://stackoverflow.com/questions/46080221/deployed-react-web-app-requires-hard-refresh
// @todo add cookies in order to postpone installation for few minutes

import React from 'react'
import withDrupalOauthConsumer from './../../drupal-oauth/withDrupalOauthConsumer'

// import { SemVer } from 'semver'
import compareVersions from 'compare-versions';

import { Modal, Button } from 'react-bootstrap'

import { connect } from 'react-redux'
import { selectApplicationVersionNumberBackend } from './../Shop/ext/redux/selectors/event'


// import { useApplicationVersionNumberBackend } from './../../../hooks/useApplicationVersionNumberBackend'
import axios from 'axios'
import useSWR from "swr"

// const fetcher = (...args) => fetch(...args).then(res => res.json())
// @see: https://stackoverflow.com/questions/65862928/how-to-send-headers-using-swr
const fetcher = (url, authorization) => {
// console.log(url)
// console.log(authorization)
    return axios
        .get(url, { 
            // method: 'GET',
            headers: {
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
                'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                // 'A': 'B',
            },
        })
        .then(res => {
// console.log(res)
            return res.data
        });
}

const intl = {
    locale: `pl`,
}

// const HOOK_RETURN_DEFAULT = {
//     applicationVersionNumberBackend: null,
//     isLoading: true,
//     isError: null,
// }

function useApplicationVersionNumberBackend(authorization) {
    // let applicationVersionNumberBackend = null;

    const { data, error } = useSWR([`${process.env.SOFT4NET_SOURCE_BASE_URL}/${intl.locale}/jsonapi/commerce_store/online`, authorization], fetcher);
// console.log(data)
// console.log(error)
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    }
/*
    // if (error) return <div>failed to load</div>
    // if (!response) return <div>loading...</div>
    if (error) return {
        ...HOOK_RETURN_DEFAULT,
        isError: error,
    }
    if (!response) return {
        ...HOOK_RETURN_DEFAULT,
    }
console.log(response)
    const { jsonapi, data, links } = response // destruct JSON:API response
console.log(data)

    if (data?.length) {
        applicationVersionNumberBackend = data?.[0]?.attributes.field_application_version_number || null;
    }

    return {
        applicationVersionNumberBackend,
        isLoading: !error && !data,
        isError: error,
    }
*/
}



const MODAL_SHARED_ATTRIBURES = {
    centered: true,
    backdrop: `static`,
}


const Component = props => {
    const authorization = props.drupalOauthClient.isLoggedIn() || null;
    // if (!authorization) {
    //     return null;
    // }
// console.log(`Component`)
    const [modalShow, setModalShow] = React.useState(true);
    // let modalShow = false;

    const applicationVersionNumberClient = process.env.SOFT4NET_VERSION_NUMBER;

    let applicationVersionNumberBackend = props.selectApplicationVersionNumberBackend();
    // let applicationVersionNumberBackend = null;
    // const applicationVersionNumberBackend = applicationVersionNumberClient;
/*
    const { data: response, isLoading, isError } = useApplicationVersionNumberBackend(authorization);
    // const { data: response, isLoading, isError } = { data: null, isLoading: true, isError: false };
// console.log(response)
    // if (isLoading) return <Spinner />
    // if (isError) return <Error />
    if (isLoading) return null
    if (isError) return null

// console.log(response)
    const { jsonapi, data, links } = response // destruct JSON:API response
// console.log(data)

    if (data?.length) {
        applicationVersionNumberBackend = data?.[0]?.attributes.field_application_version_number || null;
    }
*/
// console.log(`applicationVersionNumberBackend: `, applicationVersionNumberBackend)

    if (!applicationVersionNumberBackend) {
        return null;
    }

// console.log(`applicationVersionNumberBackend: `, applicationVersionNumberBackend)

    // React.useEffect(() => {
    //     const isUpdateAvailable = compareVersions(applicationVersionNumberBackend, applicationVersionNumberClient); // compareVersions('10.1.8', '10.0.4'); //  1
    //     // const isUpdateAvailable = true
    //     if (isUpdateAvailable) {
    //         setModalShow(true);
    //     }
    // }, [
    //     applicationVersionNumberBackend
    // ])
// console.log(`applicationVersionNumberClient: `, applicationVersionNumberClient)
// console.log(`applicationVersionNumberBackend: `, applicationVersionNumberBackend)
    const isUpdateAvailable = compareVersions(applicationVersionNumberBackend, applicationVersionNumberClient); // compareVersions('10.1.8', '10.0.4'); // possible values => [-1, 0, 1]
    // const isUpdateAvailable = compareVersions('1.1.3', '1.1.2');
// console.log(`isUpdateAvailable: `, isUpdateAvailable)
    // const isUpdateAvailable = true
    // if (isUpdateAvailable) {
    //     modalShow = true;
    // }
    if (isUpdateAvailable <= 0) {
        return null;
    }

    // if (!modalShow) {
    //     return null;
    // }

    return (
        <Modal
            id="client-update-required"
            show={modalShow}
            onHide={() => setModalShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            {...MODAL_SHARED_ATTRIBURES}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Pojawiła się nowa wersja aplikacji o numerze {applicationVersionNumberBackend}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                W celu instalacji nowej wersji kliknij poniżej "Instaluj".
                Jeśli jesteś w trakcie wykonywania ważnych czynności, wcześniej je zakończ i wróc do instalacji później!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalShow(false)}>Anuluj</Button>
                <Button variant="primary" onClick={() => {window.location.reload(true);}}>Instaluj</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        selectApplicationVersionNumberBackend: () => selectApplicationVersionNumberBackend(state),
    }
};
  
const mapDispatchToProps = null
  
export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(Component));
// export default withDrupalOauthConsumer(Component)
