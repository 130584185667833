// import { Link } from "gatsby"
import React, { useState, useRef } from "react"
import { connect } from 'react-redux'

// s4n
import { useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import loadable from '@loadable/component'
import Preloader from './s4n/Preloader'
import classNames from 'classnames'
// import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"
// import useBuildTime from './../hooks/useBuildTime'
// ES6
//import Webform from 'hn-react-webform';
// import GeneralUtils from './../utils/common'
// import Menu from "./s4n/Menu/menu"
// import MenuUtils from "./s4n/Menu/utils"
// import { simplenewsNewsletter, simplenewsSubscriber } from './s4n/Shop/ext/redux/actions/user'
// import Popup from './../components/s4n/Popup'


const Footer = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    // const { ... } = props;

    const oClassNames = classNames({
        'footer': true,
        // [`alert-${formStatus.class}`]: true,
    });
    
    return (
        <React.Fragment>

            <div className={oClassNames}>
                <div className="copyright">
                    <p>Records Zoltarpark v. {process.env.SOFT4NET_VERSION_NUMBER}</p>
                    <p>Copyright &copy; Designed &amp; Developed by <a href="#" target="_blank">Soft4Net</a> {new Date().getFullYear()}</p>
                    {/* { t({ id: "soft4net_all_rights_reserved" }) } */}
                </div>
            </div>



            {/* <UseDrupalBlockContentBasic blockId={1} />            
            {(props.nodeId === Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) || popupOpened ) && 
                <FormattedHTMLMessage 
                    id={`soft4net_newsletter_footer_message`}
                />
            } */}

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    // isHighContrastMode: state.highContrast.isHighContrastMode,
    // user: state.shopReducers.user,
})

const mapDispatchToProps = dispatch => ({
    // Get all newsletters defined in the CMS and details about them as an array
    // onSimplenewsNewsletter: (authorization, simplenews_newsletter) => dispatch(simplenewsNewsletter(authorization, simplenews_newsletter)),

    // Get information wether user is subscribed to a specific newsletter or it can PATCH sunscription to certain newsletetr switch ON / OFF
    // onSimplenewsSubscriber: (authorization, method, status, mail, subscriptions) => dispatch(simplenewsSubscriber(authorization, method, status, mail, subscriptions)),
})

export default Footer;
// export default connect(mapStateToProps, mapDispatchToProps)(Footer);