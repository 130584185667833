import React from "react"

// s4n
import { useIntl } from "gatsby-plugin-intl"
import Link from './../Link' // extends Link from gatsby-plugin-intl


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    // const { ... } = props;

    return (
      <React.Fragment>

        <div className="quixnav">
            <div className="quixnav-scroll">
                <ul className="metismenu" id="menu">
                    <li className="nav-label first">Menu</li>
                    {/* <li><a href="index.html"><i className="icon icon-single-04"></i><span className="nav-text">Dashboard</span></a></li> */}
                    <li>
                        {/* <Link 
                            to="/" 
                            // className="has-arrow" 
                            // href="javascript:void()" 
                            // aria-expanded="false"
                        >
                            <i className="icon icon-single-04"></i><span className="nav-text">Dashboard</span>
                        </Link> */}
                        <Link 
                            to="/events" 
                            // className="has-arrow" 
                            // href="javascript:void()" 
                            // aria-expanded="false"
                        >
                            <i className="ti-timer"></i><span className="nav-text">Wydarzenia</span>
                        </Link>
                        {/* <ul aria-expanded="false">
                            <li><Link to="/">Dashboard</Link></li>
                        </ul> */}
                    </li>

                    <li>
                        <Link to="/user/calendar">
                            <i className="ti-calendar"></i><span className="nav-text">Kalendarz</span>
                        </Link>
                    </li>

                    {/* <li className="nav-label">Aplikacje</li>
                    <li><a className="has-arrow" href="javascript:void()" aria-expanded="false"><i
                                className="icon icon-app-store"></i><span className="nav-text">Apps</span></a>
                        <ul aria-expanded="false">
                            <li><Link to="/user/profile">Profile</Link></li>
                            <li><a className="has-arrow" href="javascript:void()" aria-expanded="false">Email</a>
                                <ul aria-expanded="false">
                                    <li><a href="./email-compose.html">Compose</a></li>
                                    <li><a href="./email-inbox.html">Inbox</a></li>
                                    <li><a href="./email-read.html">Read</a></li>
                                </ul>
                            </li>
                            <li><Link to="/user/calendar">Calendar</Link></li>
                        </ul>
                    </li> */}

                </ul>
            </div>
        </div>

      </React.Fragment>
    )
}

export default Component;
