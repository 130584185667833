import React from 'react';
import { connect } from 'react-redux'
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import { userLogout } from './../../Shop/ext/redux/actions/user'
import GeneralUtils from './../../../../utils/common'



const LogoutLink = (props) => {
  // if (!props.userAuthenticated) {
  //   return null;
  // }

  const { component: Component, className } = props;

  return(
      <a
          // {...props}
          href="#"
          className={className}
          onClick={async (e) => {
              e.preventDefault();
              await GeneralUtils.logout(props);
          }}
    >
      {props.children ? props.children : `Log out`}
    </a>
  ); 
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    onUserLogout: () => dispatch(userLogout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withDrupalOauthConsumer(LogoutLink)));