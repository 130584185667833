// import _ from 'lodash';
// import useAllFileFile from './../../hooks/useAllFileFile'

import { client } from './../../../../../../apollo/client';
import graphqlQuery from './../../../../../../model/apollo/searchAPISearch/product_index';
import GeneralUtils from './../../../../../../utils/common'
import { CommonIndexDocument } from './../../../interface';

// Document contains indexed fields 
interface SearchAPISearchIndexDocument {
    // General
    rendered_item?: string,
    url: string,

    product_id: number,    
    product_title: string,
    product_field_model: string,
    product_field_model_alias: Array<string>,
    product_field_brand: string,
    product_variations: Array<any>,

    node_id: number,    
    node_title: string,
    node_field_date_from: Array<number>,
    node_field_date_to: Array<number>,
    node_summary_processed?: string, // body:summary_processed
    node_processed?: string, // body:processed
    // node_status?: boolean,
    // node_uid?: number,
}

const SEARCH_MIN_QUERY_LENGTH: number = 3;

class SearchBackend {

    // @see: https://stackoverflow.com/questions/32647215/declaring-static-constants-in-es6-classes
    // But unfortunately this class property syntax is only in an ES7 proposal, and even then it won't allow for adding const to the property.
    // static const SEARCH_MIN_QUERY_LENGTH = 3;
    static get SEARCH_MIN_QUERY_LENGTH(): number {
        return SEARCH_MIN_QUERY_LENGTH;
    }

    static result_count: number = 0;

    static setSiteSearchIndex = (siteSearchIndex) => {
    }

    // @todo: refactor to backend.js
    static executeQuery = async (query: string, intl: any) => {

        const oFilters = {
            index_id: `product_index`,
            intl,
            query,
        };

        const APOLLO_QUERY = graphqlQuery(oFilters);
// console.log(APOLLO_QUERY.loc.source.body)

        // let queryResults = null;

        // client
        //     .query({
        //         query: APOLLO_QUERY
        //     })
        //     .then(({ data, loading, error }) => {
        //         if (loading) return <p>Loading...</p>;
        //         if (error) return <p>Error: ${error.message}</p>;

        //         const queryResults = data.searchAPISearch;
        //         // console.log(queryResults)

        //         // return queryResults
        //         this.setState({
        //             // query: query,
        //             results: queryResults
        //         })
        //     })
        //     .catch(error => console.error(error))

        // try {
/*
            export declare type ApolloQueryResult<T> = {
                data: T;
                errors?: ReadonlyArray<GraphQLError>;
                loading: boolean;
                networkStatus: NetworkStatus;
                stale: boolean;
            };
*/
            const { data, errors, loading } = await client.query({query: APOLLO_QUERY});
        // } catch (error) {
        //     console.log(error)
        // }

        if (errors) {
            return false;
        }

        // if (loading) {
        //     return <p>Loading...</p>
        // }

        const queryResults = data.searchAPISearch;

        // console.log(queryResults)
        return queryResults;
    }

    static getAgregateResults = (aDataCollectionTranslated: Array<any>) => {
        if (SearchBackend.getResultsCount() <= 0) {
            return aDataCollectionTranslated;
        }
/*
        let mapAgregatedResults = new Map();
        (SearchBackend.getResultsCount() > 0) && aDataCollectionTranslated.map(({ node: nodeIndexFields }) => {
        // (SearchBackend.getResultsCount() > 0) && aDataCollectionTranslated.map(nodeIndexFields => {
            // console.log(nodeIndexFields)
            if (nodeIndexFields) {
                if (typeof mapAgregatedResults.get(nodeIndexFields.title) === `undefined`) {
                    mapAgregatedResults.set(nodeIndexFields.title, []);
                }
                mapAgregatedResults.get(nodeIndexFields.title).push(nodeIndexFields);
            }
        });
*/
        let oAgregatedResults = {};
        aDataCollectionTranslated.forEach(({ node: nodeIndexFields }) => {
            if (`undefined` === typeof oAgregatedResults[nodeIndexFields.title]) {
                oAgregatedResults[nodeIndexFields.title] = [];
            }
            oAgregatedResults[nodeIndexFields.title].push(nodeIndexFields);
        });

        // console.log(oAgregatedResults);

        return oAgregatedResults;

        // const aAgregatedResults = Object.keys(oAgregatedResults);
        // return aAgregatedResults;
    }

    // @todo: refactor to backend.js
    static getResults = async (query: string, intl: any) => {
        
        // const queryResults = await this.executeQuery(query, intl); // 'this' cannot be referenced in a static property initializer.ts(2334)
        const queryResults = await SearchBackend.executeQuery(query, intl);

        SearchBackend.result_count = queryResults.result_count;
        const documents: Array<SearchAPISearchIndexDocument> = queryResults.documents ? queryResults.documents : [];

        const documentsFiltered = documents.filter(nodeIndexFields => {
            return ![
                nodeIndexFields.product_title, 
                nodeIndexFields.node_title,
            ].includes(`SOFT4NET_DO_NOT_DELETE`);
        })

        // const aDocumentsWrappedWithNodeField = documents.map(nodeIndexFields => ({ node: nodeIndexFields }))
        const aDocumentsWrappedWithNodeField = documentsFiltered.map(nodeIndexFields => {

            const id = ((nodeIndexFields) => {
                let id = -1;
                if (!nodeIndexFields) {
                    return id;
                }
                if (nodeIndexFields.product_id) {
                    id = nodeIndexFields.product_id;
                }
                if (nodeIndexFields.node_id) {
                    id = nodeIndexFields.node_id;
                }
                return id;
            })(nodeIndexFields);

            const title = ((nodeIndexFields) => {
                let title = ``;
                if (!nodeIndexFields) {
                    return title;
                }
                if (nodeIndexFields.product_title) {
                    title = nodeIndexFields.product_title;
                }
                if (nodeIndexFields.node_title) {
                    title = nodeIndexFields.node_title;
                }
                return title;
            })(nodeIndexFields);

            const fields: CommonIndexDocument = {
                id,
                title,
                langcode: intl.locale,
                path: {
                    /**
                     * We get url from SearchAPI!
                     * We replace it to match gatsby router and gatsby-plugin-intl
                     * /pl/nasza-oferta/klienci-biznesowi/programy-rozwojowe-dla-firm => /nasza-oferta/klienci-biznesowi/programy-rozwojowe-dla-firm
                     */
                    alias: nodeIndexFields.url ? nodeIndexFields.url.replace('/' + intl.locale, '') : null,
                    langcode: intl.locale,
                },
            };
            
            return {
                node: fields,
            };
        });

        // Use for static search!!!
        // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
        // const aDataCollectionTranslated = CollectionDataUtils.filterData(aDocumentsWrappedWithNodeField, intl.locale, true, 'uniqueDrupalInternalId');
        const aDataCollectionTranslated = aDocumentsWrappedWithNodeField;

        return SearchBackend.getAgregateResults(aDataCollectionTranslated);
    }

    static getResultsCount = ():number => {
        return SearchBackend.result_count;
    }



    static extractFieldValue = (nodeIndexFields, fieldNameSuffix) => {
        const entitiesTypePrefix = [`product_`, `node_`];

        entitiesTypePrefix

        for (const fieldName in nodeIndexFields) {
            if (nodeIndexFields.hasOwnProperty(fieldName)) { // hasOwnProperty is used to check if your target really has that property, rather than having inherited it from its prototype.
                //  alert("Key is " + fieldName + ", value is " + nodeIndexFields[fieldName]);
                // if () {

                // }
            }
        }

        let fieldValue = ``;
        if (nodeIndexFields.product_title) {
            fieldValue = nodeIndexFields.product_title;
        }
        if (nodeIndexFields.node_title) {
            fieldValue = nodeIndexFields.node_title;
        }
        return fieldValue;
    };
}

export default SearchBackend;