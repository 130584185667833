import React from "react"
import { connect } from 'react-redux'
import { useIntl } from "gatsby-plugin-intl"
import { changeFontSize } from './../../../../s4n/state/actions/fontSize'
import classNames from 'classnames';
import { bindOnClickOnKeyDown } from './../index'

// import AMinus from './svg/a-minus'

/**
 * @see: https://stackoverflow.com/questions/40995948/how-to-add-a-dynamic-class-to-body-tag-in-gatsby-js
 * 
 * @param {*} props 
 */
const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { sFontSize } = props;
    const letters = [
        // { size: `small`, letter: <AMinus x="0px" y="0px" width="22px" height="20px" viewBox="0 0 22 20" enable-background="new 0 0 22 20" />,},
        { size: `small`, letter: `A-`,},
        { size: `normal`, letter: `A`,},
        { size: `large`, letter: `A+`,},
    ];

    

    return (
        <React.Fragment>
            <div className="accessibility-font-size">
                {letters.map((item, index) => {
                    const oClassNames = classNames({
                        [`text-size--${item.size}`]: true,
                        [`selected`]: sFontSize === item.size,
                    });

                    return (
                        <a key={`accessibility-font-size-${item.size}`} 
                            className={oClassNames}
                            // onClick={() => props.changeFontSize(item.size)}
                            // onKeyDown={() => props.changeFontSize(item.size)}
                            // ===
                            {...bindOnClickOnKeyDown(() => props.changeFontSize(item.size))}
                            tabIndex="0" 
                            title={ t({ id: `soft4net_accessibility_font_size_${item.size}` }) } 
                            aria-label={ t({ id: `soft4net_accessibility_font_size_${item.size}` }) }
                        >
                            <span aria-hidden="true">{item.letter}</span>
                        </a>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    sFontSize: state.fontSize.fontSize,
})

const mapDispatchToProps = dispatch => ({
    changeFontSize: (fontSize) => dispatch(changeFontSize(fontSize)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentStateless);