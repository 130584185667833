import React from "react"
// import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useIntl, navigate } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
// import Link from "./Link"
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
// import MenuUtils from "./../s4n/Menu/utils"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
// import loadable from '@loadable/component'
// import Preloader from '../s4n/Preloader'
// import FormBasic from "./../forms/basic"
// import FormBasicMaterialUiExample1 from "./../forms/basic.material-ui.example-1"
// import FormBasicMaterialUiExample2 from "./../forms/basic.material-ui.example-2"
// import FormBasicMaterialUiExample3 from "./../forms/basic.material-ui.example-3"
// import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
// import FormContactS4n from "./../forms/webform/contact_s4n"
// import FormBasicMaterialUi1 from "./../forms/basic.material-ui.test"
// import FormFormikJsonSchema from "./../forms/formik-json-schema"
// import Harmonogram from './../../components/s4n/Harmonogram/harmonogram'
// import Shop from './Shop/index'

// import FormikJsonSchema from "./../forms/formik-json-schema"
// import ReactJsonSchema from "./../forms/react-jsonschema-form"
// import CircleInteractive from "./../s4n/CircleInteractive"

// import { Accordion, Card } from 'react-bootstrap' // TEST for src/assets/sass/fix/bootstrap/_transitions.scss

// import ImageS4n from './image'
// import Breadcrumbs from './Menu/Breadcrumbs'
// import PromotedFrontPage from './../s4n/Promoted/FrontPage'
// import GoogleMap from './../s4n/GoogleMap'
// import FormContactS4n from './../forms/standalone/contact_s4n'

// import Reviews from './../s4n/Reviews'
// import ReadMore from './../s4n/ReadMore'
// import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
// import Link from './Link'


const ComponentFunctional = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children, name, nodeId, productId, hasFooterContactForm } = props;

    // console.log(props.location)
    // const path = globalHistory.location.pathname;

    // Loadable components START
    // let UseDrupalBlockContentBasic = null;
    let Subpages = null;
    // let Portfolio = null;
    // let GoogleMap = null;
    // let Harmonogram = null;

    // let FormContactS4n = true === hasFooterContactForm ? loadable(() => import(`./../forms/standalone/contact_s4n`), {fallback: <Preloader />}) : null;

    switch (nodeId) {
        // case 1: // Home
        case 14: // Services
            // Subpages = loadable(() => import(`./../s4n/Menu/Subpages`));
            break;
        case 16: // Contact
            // UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`), {fallback: <Preloader />})
            // GoogleMap = loadable(() => import(`./../s4n/GoogleMap`), {fallback: <Preloader />})
            break;
        // case 17: // Portfolio
        //     Portfolio = loadable(() => import(`./../s4n/Portfolio`), {fallback: <Preloader />})
        //     break;
        // case 871:
        //     Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`), {fallback: <Preloader />})
        //     break;
    }
    // const FormContactS4n = loadable(() => import(`./../forms/webform/contact_s4n`))
    // const UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`))
    // const Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`))
    // Loadable components STOP
    
    return (
        <React.Fragment>
            {/* {(() => {
                return (
                    <React.Fragment>
                        This was rendered!
                    </React.Fragment>
                )
            })()} */}

            {/* !!! Children !!! */}
            {/* <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col"> */}

                        {/* {![
                            Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID),
                            Number(process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID),
                        ].includes(Number(nodeId)) && 
                            // <!-- breadcrumb -->
                            <nav className="bg-gray py-3">
                                <div className="container">
                                    <Breadcrumbs nid={nodeId} />
                                </div>
                            </nav>
                            // <!-- /breadcrumb -->
                        } */}



                        {children}



                    {/* </div>
                </div>
            </div> */}

            {/* Harmonogram (Szkolenia) */}
            {/* {871 === nodeId && 
                <React.Fragment>
                    <Harmonogram />
                </React.Fragment>
            } */}

            {/* Services */}
            {/* {14 === nodeId && 
                <React.Fragment>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Subpages nid={nodeId} template={`service`} disableHeader={true} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            } */}

            {/* Portfolio */}
            {/* {17 === nodeId && 
                <React.Fragment>
                    <Portfolio />
                </React.Fragment>
            } */}

            {/* {MenuUtils.isCurrentUri('internal:/blog') && 
                <Blog />
            } */}

            {/* {[1062, 1083].includes(nodeId) && 
                <React.Fragment>
                    <Shop />
                </React.Fragment>
            } */}

            {/* {<Location>
                {({ location }) => {
                    // console.log(JSON.stringify(location, null, 4))
                    return (
                        // @see: https://github.com/gatsbyjs/gatsby/issues/8787
                        <div className="container">
                            { '/pl#contact' === location.pathname + location.hash && <FormBasic /> }
                            { '/pl#contact' === location.pathname + location.hash && <FormBasicMaterialUi /> }
                        </div>
                    )
                }}
            </Location>} */}





            {/* <div className={`container`}>
                <div className={`decoration-stripe py-5`}></div>

                <div className={`row _items`}>
                    {(() => {
                        const translation = {
                            link_wreaths_promotions: {
                                pl: `/wianki/promocje`,
                                en: `/wreaths/promotions`,
                                de: `/wreaths/promotions`,
                            },
                            link_wreaths_news: {
                                pl: `/wianki/nowosci`,
                                en: `/wreaths/news`,
                                de: `/wreaths/news`,
                            }
                        }

                        return (
                            <React.Fragment>
                            </React.Fragment>
                        )
                    })()}
                </div>

                <div className={`decoration-stripe py-5`}></div>

                {Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId && 
                    <React.Fragment>
                    </React.Fragment>
                }

                <Reviews />

                <Testimonials />

                {Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId && 
                    <React.Fragment>
                    </React.Fragment>
                }

            </div> */}


        </React.Fragment>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default ComponentFunctional