/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

// import Header from "./header"
import Header from "./header"
import Sidebar from "./s4n/Sidebar"
import Footer from "./footer"

// import "./layout.css"
// import cssModule from "./../assets/sass/components/Layout.module.scss"
// import "./../../node_modules/bootstrap/scss/bootstrap.scss";
import "./../assets/sass/index.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.css"
// import "./../assets/sass/HighContrast.module.css"

// s4n
// import $ from "jquery"
import { useIntl, navigate } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
// import Preloader from './s4n/Preloader'
import kebabCase from "lodash.kebabcase"
import classNames from 'classnames';
import S4nLayoutBlock from './s4n/layoutBlock'
// import * as loadScript from 'simple-load-script'
import loadScript from 'simple-load-script';
// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
import { withPrefix } from 'gatsby'
// import g_soft4NetFrontConfig from './../assets/js/s4n'
// import YupSetLocale from './../components/forms/yup-setlocale';

import drupalOauth from './drupal-oauth/drupalOauth';
import withDrupalOauthProvider from './drupal-oauth/withDrupalOauthProvider'

import { connect } from 'react-redux'
import { intlPreserve } from './../s4n/state/actions/intl'

// SHOP EXT START
// // import React, { Component } from 'react';
// // import { ApolloProvider } from 'react-apollo';
// // import { Route, Switch } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router'
// import configureStore, { history } from './../components/s4n/Shop/ext/utils/configureStore';
// // import { graphqlClient } from './utils/api';

// // import Header from './blocks/Header';
// // import CatalogMenu from './blocks/CatalogMenu'
// // import Footer from './blocks/Footer'
// // import Home from './pages/Home';
// import Cart from './../components/s4n/Shop/ext/pages/Cart';
// // import Catalog from './pages/Catalog';
// // import Product from './pages/Product';
// import { cartFetch, setCartToken } from './../components/s4n/Shop/ext/actions'
// import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// // import Checkout from './pages/Checkout'

//     // @see: https://github.com/gatsbyjs/gatsby/issues/14480
//     /**
//      * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
//      * because render is called before useEffect()!
//      */
//     // const store = configureStore();
//     // // if (typeof window !== 'undefined') {
//     //   store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
//     //   // store.dispatch(setCartToken('ajg6t2g3kz'));
//     //   store.dispatch(cartFetch());
//     // // }
// SHOP EXT STOP

// s4n
import useVersion from './../hooks/useVersion'
import useScreenSize from './../hooks/useScreenSize'

import Preloader from './s4n/Preloader'
// import ShopCheckoutIframe from './../components/s4n/Shop/s4n/Checkout/Iframe'



import { selectUserLoginIsAllowed } from './s4n/Shop/ext/redux/selectors/user'



import ClientUpdateModal from './s4n/ClientUpdateModal'



// SMOOTH_SCROLL START
// @see: https://github.com/idiotWu/react-smooth-scrollbar
// import SmoothScrollbar from 'smooth-scrollbar';
// import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
// import Scrollbar from 'react-smooth-scrollbar';
// SMOOTH_SCROLL STOP


// process.env.NODE_ENV is set only in Express server not in running contexte from simple node invoke!!!
// @see: https://stackoverflow.com/questions/16978256/what-is-node-env-in-express
if (`development` === process.env.NODE_ENV) {
  // @see: https://github.com/formatjs/formatjs/issues/465#issuecomment-369566628 START
  // Disable missing translation message as translations will be added later.
  // We can add a toggle for this later when we have most translations.

  // eslint-disable-next-line
  const consoleError = console.error.bind(console);
  // eslint-disable-next-line
  console.error = (message, ...args) => {
    if (
      typeof message === 'string' && (
        message.startsWith('[React Intl] Missing message:') || 
        message.startsWith('[React Intl] Cannot format message:')
      )
    ) {
      return;
    }
    consoleError(message, ...args);
  };
  // @see: https://github.com/formatjs/formatjs/issues/465#issuecomment-369566628 STOP
}



const Layout = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children } = props;

    const [context, setContext] = useState({
      // loadedScripts: false,
      hasTopAnnouncementBar: true,
      windowLoaded: false,
    });

    const currentBuildVersion = useVersion();

    // const [htmlElement, setHtmlElement] = useState(null);

// SMOOTH_SCROLL START
    // const scrollbarRef = useRef(null);
// SMOOTH_SCROLL STOP

    // SHOP EXT START
    // @see: https://github.com/gatsbyjs/gatsby/issues/14480
    /**
     * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
     * because render is called before useEffect()!
     */
    // const store = configureStore();
    // // if (typeof window !== 'undefined') {
    //   // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
    //   store.dispatch(setCartToken('9jg6t2g3kz'));
    //   store.dispatch(cartFetch());
    // // }
    // SHOP EXT STOP
   
    /**
     * Load 3rd party scripts async in order (local static assets mainly thanks to withPrefix)!!!
     * 
     * @see: https://stackoverflow.com/questions/34589488/es6-immediately-invoked-arrow-function
     * @see: https://stackoverflow.com/questions/40958680/react-router-how-to-trigger-document-ready
     */
    /*
      #!/bin/bash

      # create symlinks from sticky directory for local npm packages that you want to inject into body
      cd static/
      ln -s ../node_modules/jquery/dist/jquery.min.js jquery.min.js
      ln -s ../node_modules/jquery/dist/jquery.slim.min.js jquery.slim.min.js
      // ln -s ../node_modules/bootstrap/dist/js/bootstrap.min.js bootstrap.min.js
      ln -s ../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js bootstrap.bundle.min.js
      ln -s ../node_modules/owl.carousel/dist/owl.carousel.min.js owl.carousel.min.js
      ln -s ../node_modules/waypoints/lib/jquery.waypoints.min.js jquery.waypoints.min.js
      ln -s ../node_modules/waypoints/lib/shortcuts/sticky.min.js sticky.min.js
      ln -s ../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js jquery.magnific-popup.min.js
      ln -s ../node_modules/smooth-scroll/dist/smooth-scroll.polyfills.min.js smooth-scroll.polyfills.min.js
    */
    const loadScripts = async (nodeId, productId) => {
        // CDN assets
       
        // Load only at page [/pl/o-nas/historia-i-fakty || /en/about-us/history-and-facts]
        // 1055 === nodeId && 
          // await loadScript(withPrefix('/scrollreveal.min.js' + currentBuildVersion), { inBody: true }); // await loadScript('https://unpkg.com/scrollreveal', { inBody: true });

        await loadScript(withPrefix(`/theme/focus/vendor/global/global.min.js` + currentBuildVersion), { inBody: true }); // jQuery v3.4.1, Bootstrap v4.3.1, perfect-scrollbar v1.4.0, metismenu https://github.com/onokumus/metismenu#readme A jQuery menu plugin v3.0.3, 
        await loadScript(withPrefix(`/theme/focus/js/quixnav-init.js` + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix(`/theme/focus/js/custom.min.js` + currentBuildVersion), { inBody: true });
          await loadScript(withPrefix(`/theme/focus-customization/js/custom.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/theme/focus/vendor/chartist/js/chartist.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/theme/focus/vendor/moment/moment.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/theme/focus/vendor/pg-calendar/js/pignose.calendar.min.js` + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix(`/theme/focus/js/dashboard/dashboard-2.js` + currentBuildVersion), { inBody: true });
          // await loadScript(withPrefix(`/theme/focus-customization/js/dashboard/dashboard-2.js` + currentBuildVersion), { inBody: true });
        
        /**
         * Disable parent theme scripts.js and call out customized version in s4n-theme-custom.js
         */
        // await loadScript(withPrefix(`/elite-shop/js/script.js` + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix(`/s4n-theme-custom.js` + currentBuildVersion), { inBody: true });

        // @todo: use https://jscompress.com/
        await loadScript(withPrefix(`/s4n.min.js` + currentBuildVersion), {
        // await loadScript(withPrefix('/s4n.js' + currentBuildVersion), {
          inBody: true,
          /**
           * @see: https://stackoverflow.com/questions/3206050/extract-src-attribute-from-script-tag-and-parse-according-to-particular-matches
           * @see: https://stackoverflow.com/questions/2190801/passing-parameters-to-javascript-files
           */
          // attrs: {
          //   'data-soft4net-source-base-url': process.env.SOFT4NET_SOURCE_BASE_URL,
          // }
        });

        // const jQuery = window.jQuery;
        // const $ = jQuery;
    }

    const callScripts = (nodeId, productId) => {
        const g_soft4NetFrontConfig = window.g_soft4NetFrontConfig;
        const $ = window.jQuery; // we want to have jQuery from our import not from => import $ from "jquery"

        $('body').tooltip({
          selector: '[data-toggle="tooltip"]',
          // placement: 'bottom',
          container: 'body',
        });
        
        // $('body').popover({
        //   selector: '[data-toggle="popover"]',
        //   trigger: 'hover focus',
        //   // placement: 'bottom',
        //   container: 'body',
        // });

        // $(`div.modal-backdrop, div.popover`).remove(); // fix non disapearing bootstrap modal window when select in mobile category or filter!!!
    }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
      componentDidMount();
    }, []);

    const componentDidMount = async () => {
      props.intlPreserve(intl);

      // setHtmlElement(document.getElementsByTagName(`html`)[0]);

      // SHOP EXT START
      // @see: https://github.com/gatsbyjs/gatsby/issues/14480
      // const store = configureStore();
      // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
      // store.dispatch(cartFetch());
      // SHOP EXT STOP

      const { node } = props;
      let nodeId = null;
      let productId = null;

      if (node) {
        nodeId = node.drupal_internal__nid || null;
        productId = node.drupal_internal__product_id || null;
      }

      if (typeof window.g_soft4NetFrontConfig === `undefined`) {
        await loadScripts(nodeId, productId);

        // We have now available window.g_soft4NetFrontConfig from s4n.js !!!
        // callScripts(nodeId, productId);
      }

      // We have now available window.g_soft4NetFrontConfig from s4n.js !!!
      callScripts(nodeId, productId);
    }

    // useLayoutEffect(() => {
    //   loadScripts();
    // })

    // @see: https://www.gatsbyjs.org/docs/use-static-query/
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    // const useDrupalBlockContentBasic_Soft4NetFooterContactDetails = useDrupalBlockContentBasic(1);
    // console.log(useDrupalBlockContentBasic_Soft4NetFooterContactDetails);

    //console.log(useDrupalInterfaceTranslation('@items enabled', {'@items': 12}))
    //console.log(useDrupalInterfaceTranslation('soft4cms_all_rights_reserved'));

  // console.log(props.location)
  // console.log(props)

    const { node } = props;
    const className = props.className || false;

    let nodeId = null;
    let productId = null;
    let oWrapperMainClassNode = null;

    if (node) {
      nodeId = node.drupal_internal__nid || null;
      productId = node.drupal_internal__product_id || null;

      oWrapperMainClassNode = {
        // [node.internal.type]: true,
        // [`drupal_internal__nid-${node.drupal_internal__nid}`]: node.drupal_internal__nid,
        // [`drupal_internal__product_id-${node.drupal_internal__product_id}`]: node.drupal_internal__product_id,
        // [kebabCase(node.title)]: true,
      }
    }

    const oClassNamesHtml = classNames({
      // [`accessibility-high-contrast`]: props.isHighContrastMode,
      // [`accessibility-font-size-${props.fontSize}`]: true,
      // [`has-top-announcement-bar`]: context.hasTopAnnouncementBar,
    });

    // // const $html = $(`html`);
    // const html = htmlElement;
    // if (html) {
    //   if (props.isHighContrastMode) {
    //     html.classList.add(`accessibility-high-contrast`);
    //   } else {
    //     html.classList.remove(`accessibility-high-contrast`);
    //   }
    //   // html.classList.toggle(`accessibility-high-contrast`);

    //   if (props.fontSize) {
    //     html.classList.forEach(className => {
    //       if (className.startsWith(`accessibility-font-size-`)) {
    //         html.classList.remove(className);
    //       }
    //     });
    //     html.classList.add(`accessibility-font-size-${props.fontSize}`);
    //   }

    //   if (context.hasTopAnnouncementBar) {
    //     html.classList.add(`has-top-announcement-bar`);
    //   } else {
    //     html.classList.remove(`has-top-announcement-bar`);
    //   }
    // }

    const oClassNamesBody = classNames({
      [`${useScreenSize()}`]: true,
    });

    const oWrapperMainClass = classNames({
      'wrapper-main': true,
      [intl.locale]: true,
      // [data.site.siteMetadata.title]: true,
      ...oWrapperMainClassNode,
      [className]: className,

      'show': true, // prevent page loader!!! @todo: FIX IT PROPERLY
      'user-login': props.userLoginIsAllowed,
      'user-logout': !props.userLoginIsAllowed,
    });

    // const ServiceCookieCompliance = loadable(() => import('./s4n/Service/cookieCompliance'));
    // const ServiceTawkTo = loadable(() => import('./s4n/Service/tawkTo'));
    // const ServiceFacebookBadge = loadable(() => import('./s4n/Service/facebookBadge'))
    // const ServiceScrollTop = loadable(() => import('./s4n/Service/scrollTop'));
    // const ServiceNewsletter = loadable(() => import('./s4n/Service/Newsletter'));

    // const hasFooterContactForm = [
    //   // 1,  // Home
    // ].includes(Number(nodeId));

    // User is not logged in print just login form
    if (!props.userLoginIsAllowed) {
      return children;
    }

    return (

      // <Provider store={store}>
      //   <ConnectedRouter history={history}>
        <React.Fragment>

          {<Helmet
              defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
              // @todo: this removes already set classes by 3rd party plugins!!!
              htmlAttributes={{
                // class: {
                //   // ...htmlAttrs.class,
                //   // ...htmlAttributes.class,
                //   oClassNamesHtml,
                // }
                class: oClassNamesHtml,
              }}
              bodyAttributes={{
                  class: oClassNamesBody
              }}
          />}



          {/* <div id="preloader">
              <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
              </div>
          </div> */}

          <div 
            id="main-wrapper"
            className={oWrapperMainClass}
          >
              
              <ClientUpdateModal />

              <Header nodeId={nodeId} siteTitle={data.site.siteMetadata.title} />
              <Sidebar />
              <div className="content-body">
                  <div className="container-fluid">
                    <S4nLayoutBlock name="main" nodeId={nodeId} productId={productId}>
                      {children}
                    </S4nLayoutBlock>
                  </div>
              </div>
              <Footer nodeId={nodeId} />
          </div>

        </React.Fragment>

      //   </ConnectedRouter>
      // </Provider>

    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// @see: https://gatsbyguides.com/tutorial/authenticate-users-oauth
// @see: https://www.gatsbyjs.org/tutorial/authentication-tutorial/
// Initialize a new drupalOauth client which we can use to seed the context 
// provider.
const drupalOauthClient = new drupalOauth({
  drupal_root: process.env.SOFT4NET_SOURCE_BASE_URL,
  client_id: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_ID,
  client_secret: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET,
})

const mapStateToProps = state => ({
  user: state.shopReducers.user,
  userLoginIsAllowed: selectUserLoginIsAllowed(state),
  fontSize: state.fontSize.fontSize,
  isHighContrastMode: state.highContrast.isHighContrastMode,
})

const mapDispatchToProps = dispatch => ({
  intlPreserve: (intl) => dispatch(intlPreserve(intl)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthProvider(drupalOauthClient, Layout));
