import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { useIntl } from "gatsby-plugin-intl"
import { togglePopupStatus } from './../../../s4n/state/actions/popup'
// import Helmet from "react-helmet"
// import $ from 'jquery'
// import useVersion from './../../../../hooks/useVersion'

// import { domToReact } from 'html-react-parser';
import Cookies from 'universal-cookie';


const COOKIE_NAME_POPUP_NEWSLETTER = `blPopupNewsletterDisabled`;
const cookies = new Cookies();

const Component = (props) => {
    
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children } = props;

    const [canDisplayPopup, setCanDisplayPopup] = useState(false);
    useEffect(() => {
        const _canDisplayPopup = props.isPopupOpened && !cookies.get(COOKIE_NAME_POPUP_NEWSLETTER);
        setCanDisplayPopup(_canDisplayPopup || props.forceOpen);
    }, [props.isPopupOpened, props.forceOpen])

    const onPopupClose = (e) => {
        props.setPopupOpened(false);
        props.togglePopupStatus(props.isPopupOpened);

        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 10); // 10 years ahead
        cookies.set(COOKIE_NAME_POPUP_NEWSLETTER, true, {
            path: '/', // path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
            // expires: new Date(Date.now() + 2592000), // expires (Date): absolute expiration date for the cookie
            expires: nextYear,
            // maxAge: 60 * 60 * 24 * 31, //60 * 60 * 24 * 31, // ~ one month // maxAge (number): relative max age of the cookie from when the client receives it in second
        });
    }

    if (!canDisplayPopup) {
        return null;
    }

    return (
        <React.Fragment>
            <div id={`popupContainer`}>
                <div className={`popup js-popup ${props.className}`}>
                    <div onClick={onPopupClose} className="popup__overlay js-popup-close js-overlay"></div>
                    <div className="popup__box js-popup-box">                        
                        <button onClick={onPopupClose} title={t({ id: `soft4net_popup_close` })} className="popup__close popup__close--popup js-popup-close">
                            {/* <span className="icon icon-close"></span> */}
                            <i className="fas fa-times"></i>
                        </button>
                        {/* <a href="https://www.nosalowypark.pl/pakiety/szczegoly-pakietu?OfferID=221360" className="popup__img-hld">
                            <img className="popup__img" src="https://u.profitroom.pl/2019-nnosalowypark-pl/uploads/PopupBodyandMindzlotypo17.08.jpg" />
                        </a> */}
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isPopupOpened: state.popup.isPopupOpened,
})

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     togglePopupStatus: () => dispatch(togglePopupStatus(!ownProps.isPopupOpened)),
// })
const mapDispatchToProps = dispatch => ({
    togglePopupStatus: (isPopupOpened) => dispatch(togglePopupStatus(!isPopupOpened)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Component);

