import gql from 'graphql-tag';

// @see: https://graphql-search-api.readthedocs.io/en/latest/
// @see: https://graphql-search-api.readthedocs.io/en/latest/search-parameters/
export default (filters) => gql`
{
    searchAPISearch(
        index_id: "${filters.index_id}",
        language: "${filters.intl.locale}",
        
        # @see: https://graphql-search-api.readthedocs.io/en/latest/search-parameters/#conditions
        condition_group: {
            groups: [
                {
                    conjunction: OR,
                    conditions: [
                        {
                            operator: "=", 
                            name: "product_title", 
                            value: "${filters.query}",
                        },
                        {
                            operator: "=", 
                            name: "product_body_processed", 
                            value: "${filters.query}",
                        },
                        {
                            operator: "=", 
                            name: "field_category_entity_name", 
                            value: "${filters.query}",
                        },
                        {
                            operator: "=", 
                            name: "field_category_entity_description_processed", 
                            value: "${filters.query}",
                        },
                    ],
                },
            ]
        },

        # fulltext: {
        #     keys: "${filters.query}",
        #     # A list of fields that the fulltext search is to be performed on. If left blank the search will be done on all fulltext fields.
        #     # fields: ["product_title", "product_field_brand", "product_field_model", "product_field_model_alias"],
        #     # conjunction: "OR",
        # },
        # Take fields from Search API certain index page, ex. /admin/config/search/search-api/index/product_index/fields
        # conditions: [
        #     {
        #         operator: "=", 
        #         name: "product_title", 
        #         value: "${filters.query}",
        #     },
        #     {
        #         operator: "=", 
        #         name: "product_body_processed", 
        #         value: "${filters.query}",
        #     },
        #     {
        #         operator: "=", 
        #         name: "field_category_entity_name", 
        #         value: "${filters.query}",
        #     },
        #     {
        #         operator: "=", 
        #         name: "field_category_entity_description_processed", 
        #         value: "${filters.query}",
        #     },
        # ],
        # sort: {
        #     field: "field_date_from",
        #     value: "desc"
        # },
        sort: {
            field: "product_title",
            value: "asc"
        },
        # range: {
        #     start: 0,
        #     end: 2
        # },
        # conditions: {
        #     operator: "=",
        #     name: "title",
        #     value: "Aplikacje"
        # },
        # facets: {
        #     field: "fields_tags",
        #     limit: 3,
        #     operator: "=",
        #     min_count: 1,
        #     missing: false,
        # }
    ) {
        result_count
        documents {
            # index_id
            # ... on DefaultIndexDoc {
            #     nid
            #     title
            #     field_tags
            #     # rendered_item
            # }
            ... on ProductIndexDoc {
                # url
                product_id
                product_title
                product_variations

                # node_id
                # node_title
            }
        }
        # facets {
        #     name
        #     values {
        #         count
        #         filter
        #     }
        # }
    }
}  
`