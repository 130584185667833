import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"
import EventEntity from './../../../../AccountManagement/Events/Entity'

// export const selectEventTypes = (state) => state.shopReducers?.event?.event_types?.data;
// export const selectProducts = (state) => state.shopReducers?.event?.product_fetch?.data;
// @see: https://github.com/reduxjs/reselect/blob/master/README.md#q-how-do-i-create-a-selector-that-takes-an-argument
// const expensiveItemSelectorFactory = selectedEventType => {
//   return createSelector(
//     shopItemsSelector,
//     items => items.filter(item => item.value > minValue)
//   )
// }
// const subtotalSelector = createSelector(
//   expensiveItemSelectorFactory(200),
//   items => items.reduce((acc, item) => acc + item.value, 0)
// )
// export const selectEventTypeProductVariants = (state, selectedEventType) => fromEvent.selectEventTypeProductVariants(state, selectedEventType);
// export const selectEventTypeProducts = createSelector( // use reselect library to momoize results of function, but how can i pass extra params?
//   selectEventTypes, selectProducts,
//   (eventTypes, products) => eventTypes.find()
// );
export const selectEvent = (state) => state.shopReducers?.event;
export const selectEventTypes = (state) => selectEvent(state)?.event_types?.data.filter(taxonomyTermEventType => taxonomyTermEventType.attributes.status); // when user is authenticated we also receive unpublished items, those with status === false!!!
export const selectEventTypeByUuid = (state, uuid) => selectEventTypes(state).find(taxonomyTermEventType => taxonomyTermEventType.id === uuid);
export const selectProducts = (state) => selectEvent(state)?.product_fetch?.data;
export const selectProductVariationByUuid = (state, uuid) => {
  const mappedIncludes = getMappedIncludes(selectEvent(state)?.product_fetch?.included);
  let variant = null;

  selectProducts(state)
    .forEach(commerceProductDefault => { // commerce_product--default[]
      // when user is authenticated we also receive unpublished items, those with status === false!!!
      // if (!commerceProductDefault.attributes.status) {
      //   return;
      // }

      const variations = getRelationshipFromMappedIncludes(commerceProductDefault, `variations`, mappedIncludes); // commerce_product_variation--default[]

      const found = variations?.find(variation => variation.id === uuid);
      
      // when user is authenticated we also receive unpublished items, those with status === false!!!
      // if (!found.attributes.status) {
      //   return;
      // }

      if (found) {
        variant = found;
      }
    })

  return variant;
}
export const selectCommerceProductDefaultProductVariants = (state) => {
  const mappedIncludes = getMappedIncludes(selectEvent(state)?.product_fetch?.included);
  let variants = [];

  selectProducts(state)
    .forEach(commerceProductDefault => { // commerce_product--default[]

      // when user is authenticated we also receive unpublished items, those with status === false!!!
      // if (!commerceProductDefault.attributes.status) {
      //   return;
      // }

      if (`SOFT4NET_DO_NOT_DELETE` === commerceProductDefault.attributes.title) {
        return;
      }

      const variations = getRelationshipFromMappedIncludes(commerceProductDefault, `variations`, mappedIncludes); // commerce_product_variation--default[]

      variations?.forEach(variation => {
        // when user is authenticated we also receive unpublished items, those with status === false!!!
        // if (!variation.attributes.status) {
        //   return;
        // }

        variants.push({
          code: variation.id,
          label: `${commerceProductDefault.attributes.title} ${variation.id === `8774110d-1357-41af-87fe-c2ba6d86cd2e` ? `` : `(${variation.attributes.sku})`}`, // 8774110d-1357-41af-87fe-c2ba6d86cd2e => variacja "Dowolna kwota" bez podatku
        });
      });
    })

  return variants;
}
export const selectEventTypeProductVariants = (state, selectedEventType) => {
// console.log(`selectedEventType: `, selectedEventType)
  const mappedIncludes = getMappedIncludes(selectEvent(state)?.product_fetch?.included);
  let variants = [];

  selectProducts(state)
    .filter(commerceProductDefault => commerceProductDefault?.relationships?.field_event_type?.data?.find(taxonomyTermEventTypes => taxonomyTermEventTypes.id === selectedEventType)) // filter options by selected event type | i changed field_event_type from single value to multiple values, since then data is an array not an object!!!
    .forEach(commerceProductDefault => { // commerce_product--default[]
      // when user is authenticated we also receive unpublished items, those with status === false!!!
      // if (!commerceProductDefault.attributes.status) {
      //   return;
      // }

      const variations = getRelationshipFromMappedIncludes(commerceProductDefault, `variations`, mappedIncludes); // commerce_product_variation--default[]
      // const default_variation = getRelationshipFromMappedIncludes(commerceProductDefault, 'default_variation', mappedIncludes); // commerce_product_variation--default
      // const field_event_type = getRelationshipFromMappedIncludes(commerceProductDefault, 'field_event_type', mappedIncludes); // taxonomy_term--event_types                  
      variations?.forEach(variation => {
// console.log(variation) // "commerce_product_variation--default"
// console.log(`NIE: `, variation.attributes.sku)
        // when user is authenticated we also receive unpublished items, those with status === false!!!
//         if (!variation.attributes.status) {
// // console.log(variation)
//           return;
//         }
// console.log(`TAK: `, variation.attributes.sku)
        variants.push({
          code: variation.id,
          label: `${commerceProductDefault.attributes.title} ${variation.id === `8774110d-1357-41af-87fe-c2ba6d86cd2e` ? `` : `(${variation.attributes.sku})`}`, // 8774110d-1357-41af-87fe-c2ba6d86cd2e => variacja "Dowolna kwota" bez podatku
        });
      });
    })

  if (variants.length === 0) {
    variants = selectCommerceProductDefaultProductVariants(state);
  }

  return variants;
}

export const selectYesterdayEventsHasCloseEvent = (state) => {
  return selectEvent(state)?.yesterdayHasCloseEvent || false;
}
export const selectTodayEvents = (state) => selectEvent(state)?.today?.data;
export const selectLastOpenDayEvent = (state) => selectEvent(state)?.lastOpenDayEvent;
export const selectTodayEventsHasOpenEvent = (state) => { // entity === "node--event"
  return selectTodayEvents(state).find(entity => {
    const eventTypeUuid = entity?.relationships?.field_event_type?.data.id || null;
    return EventEntity.isDayOpen(eventTypeUuid)
  })
}
export const selectTodayEventsHasCloseEvent = (state) => {
  return selectTodayEvents(state).find(entity => { // entity === "node--event"
    const eventTypeUuid = entity?.relationships?.field_event_type?.data.id || null;
    return EventEntity.isDayClose(eventTypeUuid)
  })
}

// export const selectEventsByType = (state, selectedEventType) => {
//   return null;
// }

export const selectGetCashRegisterAmount = (state) => selectEvent(state)?.cashRegisterAmount;

export const selectApplicationVersionNumberBackend = (state) => selectEvent(state)?.applicationVersionNumberBackend;